import React, { Component } from "react";
import { Infopicsmall } from "./infopicsmall";
import './shopping.css'

export default class Infopic extends Component {
    state = {
        picNo: 0,
    };

    handlePic = (id) => {
        this.setState({
            picNo: id,
        });
    };
    render() {
        return (
            <div>
                <div id="bigpic">
                    <img
                        src={this.props?.piclink[this.state.picNo]}
                        className="card-img-top"
                        width="200px" alt="bigpic" id="card-img-top-1"
                    />
                </div>

                <div className="row">
                    {this.props?.piclink?.map((item, index) => (
                        <Infopicsmall
                            key={index}
                            item={item}
                            handlePic={this.handlePic}
                            index={index}
                            picNo={this.state.picNo}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
