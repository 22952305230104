import React from 'react'
import './shopping.css'
import { Link } from 'react-router-dom'

export const AlertCart = () => {
    return (
        <div className="col" id="alertCart">
            <div>
              <strong className="mr-auto text-centre">Shopping Cart</strong>
             </div>
             <div className="toast-body">
                Item add successful!
             </div>
             <div className="justify-content-center">
                <Link to="/cart"><button className="btn-sm btn-info" id="showcartitem">Go to Cart</button></Link>
             </div>
        </div>
    )
}
