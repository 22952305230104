import {FETCH_TVS, FETCH_TV} from '../actions'

const initialState = {
    tvs: [],
    tv: {}
}

export default function(state = initialState, action) {
    
    switch(action.type){
        case FETCH_TVS:
            return{
                ...state, 
                tvs: action.payload
            }
        case FETCH_TV:
            // state = {...state, tv: {}}
            return{
                ...state,
                tv: action.payload
            }
        default:
            return state

    }
}