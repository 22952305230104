import React from 'react'

export default function CartHeater() {
    return (
        <div className="container-fluid text-center d-none d-lg-block align-items-center">
        <div className="row align-items-center">
            <div className="col-10 mx-auto col-lg-2 align-items-center">
                <p className="text-uppercase">Products</p>
            </div>
            <div className="col-10 mx-auto col-lg-2">
                <p className="text-uppercase">name of product</p>
            </div>
            <div className="col-10 mx-auto col-lg-2">
                <p className="text-uppercase">price</p>
            </div>
            <div className="col-10 mx-auto col-lg-2">
                <p className="text-uppercase">quantity</p>
            </div>
            <div className="col-10 mx-auto col-lg-2">
                <p className="text-uppercase">remove</p>
            </div>
            <div className="col-10 mx-auto col-lg-2">
                <p className="text-uppercase">total</p>
            </div>
        </div>
    </div>
    )
}
