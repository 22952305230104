import React, { Component } from "react";
import "./admin.css";
import axios from "axios";
import Alert from "./alert";
import { getErrors } from "../actions/errorActions";
import { getMessage, createMessage } from "../actions/messageActions";
import { connect } from "react-redux";

class UserRigister extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    error: {},
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const user = {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
    };
    axios
      .post("http://winbigcareer.com:8000/api/user/", user)
      .then((res) => {
        console.log(res.data);
        this.props.createMessage({ userAdded: "User Added" });
        window.location.href = "/admin";
      })
      .catch((e) => {
        const errors = {
          msg: e.response.data,
          status: e.response.status,
        };
        this.props.getErrors(errors);
        this.setState({
          error: e.response.data,
        });
      });
  };

  render() {
    const { username, email, password, error } = this.state;

    return (
      <div className="sign-modify">
        <Alert />
        <p>{error.message}</p>

        <form className="form-signin" onSubmit={this.handleSubmit}>
          <h1 className="h3 mb-3 font-weight-normal">Rigister</h1>
          <label htmlFor="inputUsername" className="sr-only">
            User Name
          </label>
          <input
            name="username"
            value={username}
            onChange={(event) => this.handleChange(event)}
            type="username"
            id="inputUsername"
            className="form-control"
            placeholder="User Name"
            required
            autoFocus
          />
          <label htmlFor="inputEmail" className="sr-only">
            Email address
          </label>
          <input
            name="email"
            value={email}
            onChange={(event) => this.handleChange(event)}
            type="email"
            id="inputEmail"
            className="form-control"
            placeholder="Email address"
            required
          />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            name="password"
            value={password}
            onChange={(event) => this.handleChange(event)}
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="Password"
            required
          />
          <div className="checkbox mb-3"></div>
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Rigister
          </button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getErrors,
  getMessage,
  createMessage,
};

export default connect(null, mapDispatchToProps)(UserRigister);
