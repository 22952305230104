import React, { Component } from "react";
import Adminpicsmall from "./adminpicsmall";
import "../../shopping/shopping.css";

export default class Adminpic extends Component {
    state = {
        picNo: 0,
    };

    handlePic = (id) => {
        this.setState({
            picNo: id,
        });
    };

    display = () => {
        if (this.props.piclink) {
            if (this.props.piclink.length > 0) {
                return (
                    <div>
                        <div id="bigpic">
                            <img
                                src={this.props?.piclink[this.state.picNo].piclink}
                                className="card-img-top"
                                width="200px"
                                alt="bigpic"
                                id="card-img-top-1"
                            />
                        </div>

                        <div className="row">
                            {this.props?.piclink?.map((item, index) => (
                                <Adminpicsmall
                                    key={index}
                                    item={item}
                                    handlePic={this.handlePic}
                                    index={index}
                                    picNo={this.state.picNo}
                                />
                            ))}
                        </div>
                    </div>
                );
            } else {
                return <div>Add pic</div>;
            }
        } else {
            return <div>Loading...</div>;
        }
    };

    render() {
        return <div>{this.display()}</div>;
    }
}
