import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteTv, listTv, updateTv } from "../../actions/tvadminActions";
import { Link } from "react-router-dom";

class Listitem extends Component {
  state = {
    showBrandInput: false,
    showSizeInput: false,
    showPriceInput: false,
    brandnew: this.props.item.brand,
    sizenew: this.props.item.size,
    pricenew: this.props.item.price,
  };

  deleteTv = (id) => {
    this.props.deleteTv(id, this.props.cookie);
    // this.props.listTv()
  };

  doubleclick = (e) => {
    switch (e.target.id) {
      case "brandnew":
        this.setState({
          showBrandInput: !this.state.showBrandInput,
        });
        break;
      case "sizenew":
        this.setState({
          showSizeInput: !this.state.showSizeInput,
        });
        break;
      case "pricenew":
        this.setState({
          showPriceInput: !this.state.showPriceInput,
        });
        break;
      default:
    }
    // console.log(this.state);
  };

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    let value1 = {}
    switch (e.target.id) {
      case "brandnew1":
        value1 = {
          ...this.props.item,
          brand: this.state.brandnew,
        };
        this.setState({
          showBrandInput: false,
        });
        break
      case "sizenew1":
        value1 = {
          ...this.props.item,
          size: this.state.sizenew,
        };
        this.setState({
          showSizeInput: false,
        });
        break
      case "pricenew1":
        value1 = {
          ...this.props.item,
          price: this.state.pricenew,
        };
        this.setState({
          showPriceInput: false,
        });
        break

      default:

    }

    this.props.updateTv(this.props.item.id, value1, this.props.cookie);
  };

  render() {
    const { item } = this.props;
    const { brandnew, sizenew, pricenew, showBrandInput, showSizeInput, showPriceInput } = this.state;
    return (
      <tr>
        <td id="brandnew" onDoubleClick={(e) => this.doubleclick(e)}>
          {showBrandInput ? (
            <div>
              <input
                type="text"
                name="brandnew"
                className="form-control"
                value={brandnew}
                onChange={(e) => this.handelChange(e)}
              />
              <span className="text-info ml-2">
                <i
                  className="fas fa-check icon-button-group"
                  id="brandnew1"
                  onClick={(e) => this.handleSubmit(e)}
                ></i>
              </span>
              <span className="text-danger ml-2">
                <i
                  className="fas fa-times icon-button-group"
                  id="brandnew"
                  onClick={(e) => this.doubleclick(e)}
                ></i>
              </span>
            </div>
          ) : (
              brandnew
            )}
        </td>
        <td id="sizenew" onDoubleClick={(e) => this.doubleclick(e)}>
          {showSizeInput ? (
            <div>
              <input
                type="number"
                name="sizenew"
                className="form-control"
                value={sizenew}
                onChange={(e) => this.handelChange(e)}
              />
              <span className="text-info ml-2">
                <i
                  className="fas fa-check icon-button-group"
                  id="sizenew1"
                  onClick={(e) => this.handleSubmit(e)}
                ></i>
              </span>
              <span className="text-danger ml-2">
                <i
                  className="fas fa-times icon-button-group"
                  id="sizenew"
                  onClick={(e) => this.doubleclick(e)}
                ></i>
              </span>
            </div>
          ) : (
              sizenew
            )}

        </td>
        <td id="pricenew" onDoubleClick={(e) => this.doubleclick(e)}>
          {showPriceInput ? (
            <div>
              <input
                type="number"
                name="pricenew"
                className="form-control"
                value={pricenew}
                onChange={(e) => this.handelChange(e)}
              />
              <span className="text-info ml-2">
                <i
                  className="fas fa-check icon-button-group"
                  id="pricenew1"
                  onClick={(e) => this.handleSubmit(e)}
                ></i>
              </span>
              <span className="text-danger ml-2">
                <i
                  className="fas fa-times icon-button-group"
                  id="pricenew"
                  onClick={(e) => this.doubleclick(e)}
                ></i>
              </span>
            </div>
          ) : (
              pricenew
            )}

        </td>
        <td>
          <Link to={`/tvinfo/${item.id}`}>
            <button className="btn btn-sm btn-outline-info">Info</button>
          </Link>
        </td>
        <td>
          <Link to={`/tvedit/${item.id}`}>
            <button className="btn btn-sm btn-outline-primary ml-3">
              Edit
            </button>
          </Link>
        </td>
        <td>
          <button
            className="ml-3 btn btn-sm btn-outline-danger"
            onClick={() => {
              if (window.confirm("are you sure")) {
                this.deleteTv(item.id);
              }
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  cookie: state.tvadmin.token,
});

const mapDispatchToProps = {
  deleteTv,
  listTv,
  updateTv,
};

export default connect(mapStateToProps, mapDispatchToProps)(Listitem);
