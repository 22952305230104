import React from "react";
import "../../shopping/shopping.css";
import { connect } from "react-redux";
import { deletePic } from "../../actions/tvadminActions";

const Adminpicsmall = ({
    index,
    item,
    handlePic,
    picNo,
    deletePic,
    cookie,
}) => {

    const ondeletePic = (id) => {
        deletePic(id, cookie);
    };
    const styledisplay =
        picNo === index ? { borderBottom: "1px solid #000000" } : { color: "none" };
    return (
        <div className="mt-3 p-2 col-3">
            <div id="smallpic" style={styledisplay} onClick={() => handlePic(index)}>
                <img
                    src={item.piclink}
                    className="pic-items"
                    width="100%"
                    alt="smallpic"
                />
            </div>
            <p
                className="text-center text-danger mt-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    if (window.confirm("are you sure")) {
                        ondeletePic(item.id);
                    }
                }}
            >
                X
      </p>
        </div>
    );
};
const mapStateToProps = (state) => ({
    cookie: state.tvadmin.token,
});

const mapDispatchToProps = {
    deletePic,
};

export default connect(mapStateToProps, mapDispatchToProps)(Adminpicsmall);
