import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchTv } from "../actions/tvActions";
import { addCart } from "../actions/cartActions";
import { Link } from 'react-router-dom'
import Infopic from './infopic';
import { AlertCart } from './alertCart'

class Showinfo extends Component {

    state = {
        qty: 1,
        addCart: false
    }

    componentDidMount() {
        this.props.fetchTv(this.props.match.params.id);
    }

    handleChange = e => {
        this.setState({ qty: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.addCart(this.props.tv[0], this.state.qty)
        this.setState({ addCart: true })
        setTimeout(this.addToCart, 3000)
    }

    addToCart = () => {
        this.setState({ addCart: false })
    }

    render() {
        if (this.props.tv[0]) {
            const { piclink, brand, size, price, rating, description, id } = this.props.tv[0]
            const addCartShow = this.state.addCart ?
                <AlertCart />
                : "";
            return (
                <div className="row">
                    {addCartShow}
                    <div className="col-sm-6 mb-3">
                        <Infopic piclink={piclink} />
                        {/* <div className="items mb-3 row">
                            <img src={piclink[0]} className="card-img-top" width="200px" />
                            {piclink.map((item, index) => <div key={index} ><img src={item} className="pic-items" width="100px" /></div>
                            )}

                        </div> */}
                    </div>
                    <div className=" col-sm-6 d-flex align-items-center mt-3">
                        <div className="card">
                            <h5 className="card-header">Information</h5>
                            <div className="card-body">
                                <h5 className="card-title">
                                    {brand} {size}
                                </h5>
                                <p className="card-text">{description}</p>
                                <h5 className="card-title">${price}</h5>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">

                                        <div className="col-4">
                                            <input type="number" className="form-control" value={this.state.qty} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                        <div className="col-8">
                                            <button className="btn btn-primary" >
                                                Add to Cart
                                    </button>
                                        </div>


                                    </div>
                                </form>
                            </div>
                            <div>
                                <Link to="/"><button className="btn btn-warning btn-block">Back</button></Link>
                            </div>
                        </div>
                    </div>

                </div>
            );
        } else {
            return <div>Loading....</div>
        }


    }
}

const mapStateToProps = (state) => {
    return {
        tv: state.tvs.tv,
    };
};

export default connect(mapStateToProps, { fetchTv, addCart })(Showinfo);
