import React, { Component } from 'react'
import { connect } from 'react-redux';
import { fetchTvs } from '../actions/tvActions';
import { Showitem } from './showitem';
import SortTv from './sortTv';

class Shopping extends Component {

    componentDidMount() {
        this.props.fetchTvs(0);
    }

    render() {
        const tvsDisplay = this.props.tvs.tvs.map((item, index) =>
            <div key={index}>
                <Showitem item={item} />
            </div>
        )
        return (
            <div className="container mt-3 mb-3">
                <div className="col">
                    <SortTv />
                    <div className="row mt-3 mb-3">
                        {tvsDisplay}
                    </div>
                </div>
            </div>


        )
    }
}

const mapStateToProps = state => ({
    tvs: state.tvs
})


export default connect(mapStateToProps, { fetchTvs })(Shopping)