import React from "react";
import "./App.css";
import Applist from "./components/applist";

function App() {
  return (
    <div>
      <Applist />
    </div>
  );
}

export default App;
