import { FETCH_TVS, FETCH_TV } from "../actions";

import requestapi from "../api/requestapi";

import webapi from "../api/webapi";

export const fetchTvs = (value) => async (dispatch) => {
  function sortTv(evt) {
    switch (value) {
      case 0:
        return evt;
      case "1":
        return evt.sort(function (tv1, tv2) {
          return tv1.price - tv2.price;
        });
      case "2":
        return evt.sort(function (tv1, tv2) {
          return tv2.price - tv1.price;
        });
      case "3":
        return evt.sort(function (tv1, tv2) {
          return tv1.size - tv2.size;
        });
      case "4":
        return evt.sort(function (tv1, tv2) {
          return tv2.size - tv1.size;
        });
      case "5":
        return evt.sort(function (tv1, tv2) {
          return tv1.rating - tv2.rating;
        });
      case "6":
        return evt.sort(function (tv1, tv2) {
          return tv2.rating - tv1.rating;
        });
      default:
        return evt;
    }
  }

  await webapi
    .get("tvs/0")
    .then((res) =>
      dispatch({
        type: FETCH_TVS,
        payload: sortTv(res.data),
      })
    )
    .catch((e) => console.log(e));
};

const actFetchSong = () => {
  return async (dispatch) => {
    let res = undefined;
    try {
      res = await requestapi.get("tvs");
      return dispatch({
        type: FETCH_TVS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };
};

export const fetchTv = (id) => async (dispatch) => {
  console.log(id);

  await webapi
    .get(`tvs/${id}`)
    .then((res) =>
      dispatch({
        type: FETCH_TV,
        payload: res.data,
      })
    )
    .catch((e) => console.log(e));
};
