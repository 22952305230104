import React, { Component } from "react";
import { connect } from "react-redux";
import { getCart, decrease, increase, remove } from "../../actions/cartActions";

class Cartlist extends Component {

    cartRemove = id => {
        console.log("remove start", id);
        
        this.props.remove(id)
    }

    cartDecrease = (id, amount) => {
        this.props.decrease(id, amount)
    }

    cartIncrease = (id, amount) => {
        this.props.increase(id, amount)
    }

    render() { 
            const cartDisplay = this.props.cart.cart.map((item, index) => {
                const {
                    id,
                    brand,
                    description,
                    size,
                    piclink,
                    price,
                    amount,
                } = item;
                const total = price * amount;
                return (
                    <div key={index} className="row my-2 text-capitalize text-center align-items-center">
                        <div className="col-10 mx-auto col-lg-2">
                            <img src={piclink} alt="pic" className="img-fluid" />
                        </div>
                        <div className="col-10 mx-auto col-lg-2">{description}</div>
                        <div className="col-10 mx-auto col-lg-2">${price}</div>
                        <div className="col-10 mx-auto col-lg-2 my-2 my-lg-0">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <span className="btn btn-black mx-1" onClick={() => this.cartDecrease(id, amount)}>-</span>
                                    <span className="btn btn-black mx-1">{amount}</span>
                                    <span className="btn btn-black mx-1" onClick={() => this.cartIncrease(id, amount)}>+</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-10 mx-auto col-lg-2">
                            <div
                                className="cart-icon" onClick={() => this.cartRemove(id)}
                                style={{cursor: "pointer", fontSize: "1.2rem", color: "red"}}
                            >
                                <i className="fas fa-trash"></i>
                            </div>
                        </div>
                        <div className="col-10 mx-auto col-lg-2">
                            <strong>${total}</strong>
                        </div>
                    </div>
                );
            });

            return <div>
                {cartDisplay}
            </div>;
        

    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

export default connect(mapStateToProps, { getCart, decrease, increase, remove })(Cartlist);
