import {
  ADD_TV,
  EDIT_TV,
  DELETE_TV,
  LIST_TV,
  INFO_TV,
  ADD_TVPIC,
  UPDATE_TV,
  DELETE_TVPIC,
  ADD_COOKIE,
} from "../actions";
import requestapi from "../api/requestapi";
import history from "../History";
import { getMessage, createMessage } from "./messageActions";

export const addTv = (values, token) => async (dispatch) => {
  // console.log(token);

  let res = undefined;
  try {
    res = await requestapi.post("tvs/", values, {
      headers: { Authorization: `Token ${token}` },
    });
    dispatch(createMessage({ addTvok: "Added Successful" }));
    dispatch({
      type: ADD_TV,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const listTv = (token) => async (dispatch) => {
  // console.log(token);

  let res = undefined;
  try {
    res = await requestapi.get("tvs/", {
      headers: { Authorization: `Token ${token}` },
    });
    dispatch({
      type: LIST_TV,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteTv = (id, token) => async (dispatch) => {
  let res = undefined;
  try {
    res = await requestapi.delete(`tvs/${id}/`, {
      headers: { Authorization: `Token ${token}` },
    });
    dispatch(createMessage({ deleteTvok: "Deleted Successful" }));
    dispatch({
      type: DELETE_TV,
      payload: { id },
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const editTv = (id, token) => async (dispatch) => {
  let res = undefined;
  try {
    res = await requestapi.get(`tvs/${id}/`, {
      headers: { Authorization: `Token ${token}` },
    });
    dispatch({
      type: EDIT_TV,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const updateTv = (id, values, token) => async (dispatch) => {
  let res = undefined;
  try {
    res = await requestapi.put(`tvs/${id}/`, values, {
      headers: { Authorization: `Token ${token}` },
    });

    dispatch({
      type: UPDATE_TV,
      payload: res.data,
    });
    history.push("/tvadd");
    dispatch(createMessage({ updateTvok: "Updated Successful" }));
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const infoTv = (id, token) => async (dispatch) => {
  let res = undefined;
  try {
    res = await requestapi.get(`tvinfo/${id}/`, {
      headers: { Authorization: `Token ${token}` },
    });
    dispatch({
      type: INFO_TV,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const addPic = (values, token) => async (dispatch) => {
  let res = undefined;
  try {
    res = await requestapi.post("tvspic/", values, {
      headers: { Authorization: `Token ${token}` },
    });
    dispatch(createMessage({ addPicok: "Picture Added Successful" }));
    dispatch({
      type: ADD_TVPIC,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deletePic = (id, token) => async (dispatch) => {
  let res = undefined;
  try {
    res = await requestapi.delete(`tvspic/${id}`, {
      headers: { Authorization: `Token ${token}` },
    });
    dispatch(createMessage({ deletePicok: "Picture Deleted Successful" }));
    dispatch({
      type: DELETE_TVPIC,
      payload: { id },
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const addCookie = (value) => ({
  type: ADD_COOKIE,
  payload: value,
});
