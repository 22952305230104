import {
  ADD_TV,
  EDIT_TV,
  DELETE_TV,
  LIST_TV,
  UPDATE_TVS,
  INFO_TV,
  ADD_TVPIC,
  DELETE_TVPIC,
  ADD_COOKIE,
  UPDATE_TV,
} from "../actions";

const initialState = {
  tvList: [],
  tvInfo: {},
  infoTv: {},
  token: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LIST_TV:
      return {
        ...state,
        tvList: payload,
      };

    case ADD_TV:
      return {
        ...state,
        tvList: [...state.tvList, payload],
      };

    case EDIT_TV:
      return {
        ...state,
        tvInfo: payload,
      };

    case UPDATE_TV:
      //   console.log("edit");
      console.log(payload);

      let tempTv = state.tvList.map((item) =>
        item.id === payload.id ? payload : item
      );
      console.log(tempTv);

      return {
        ...state,
        tvList: tempTv,
      };

    case DELETE_TV:
      return {
        ...state,
        tvList: state.tvList.filter((item) => item.id !== payload.id),
      };
    case INFO_TV:
      return {
        ...state,
        infoTv: payload,
      };
    case ADD_TVPIC:
      return {
        ...state,
        infoTv: {
          ...state.infoTv,
          piclink: [
            ...state.infoTv.piclink,
            { id: payload.id, piclink: payload.piclink },
          ],
        },
      };

    case DELETE_TVPIC:
      return {
        ...state,
        infoTv: {
          ...state.infoTv,
          piclink: state.infoTv.piclink.filter(
            (item) => item.id !== payload.id
          ),
        },
      };

    case ADD_COOKIE:
      return {
        ...state,
        token: payload,
      };

    default:
      return state;
  }
}
