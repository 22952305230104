import React from "react";
import Shopping from "./shopping/shopping";
import Admin from "./admin/admin";
import AdminMain from "./admin/main";
import history from "./History";
import Default from "./default";
import { Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { store } from "./store";
import Showinfo from "./shopping/showinfo";
import Cart from "./shopping/cart/cart";
import UserRigister from "./admin/userRigister";
import Tvadd from "./admin/tvadmin/tvadd";
import EditTv from "./admin/tvadmin/tvedit";
import Tvinfo from "./admin/tvadmin/tvinfo";
import { CookiesProvider } from "react-cookie";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
// const hosturl = "redux/redux-shopping/"
export default function Applist() {
  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <CookiesProvider>
          <Router history={history}>
            <Switch>
              <Route path="/" exact component={Shopping} />
              <Route path="/admin" component={Admin} />
              <Route path="/tvadd" component={Tvadd} />
              <Route path="/tvedit/:id" component={EditTv} />
              <Route path="/tvinfo/:id" component={Tvinfo} />
              <Route path="/main" component={AdminMain} />
              <Route path="/userrigister" component={UserRigister} />
              <Route path="/cart" component={Cart} />
              <Route path="/showinfo/:id" component={Showinfo} />
              <Route component={Default} />
            </Switch>
          </Router>
        </CookiesProvider>
      </AlertProvider>
    </Provider>
  );
}
