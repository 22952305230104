import React from 'react'
import './admin.css'

export default function AdminNav() {
    return (
        <div>
            <div id="layoutSidenav_nav">
                <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div class="sb-sidenav-menu">
                        <div class="nav">
                           
                          
                            <div class="sb-nav-link-icon"><i class="fas fa-columns"></i> </div>
    {" "}Layouts
                                
                           </div>
                           </div>
                </nav>
            </div>
        </div>
    )
}
