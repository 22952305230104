import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { editTv, updateTv } from "../../actions/tvadminActions";
import { Link } from "react-router-dom";
import { isSigned } from "./isSigned";

export class EditTv extends Component {
  componentDidMount() {
    isSigned(this.props.cookie);
    this.props.editTv(this.props.match.params.id, this.props.cookie);
  }

  renderInput = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          className="form-control"
          type={type}
          {...input}
          placeholder={label}
        />
        {touched && error && <span className="text-danger">{error}</span>}
      </div>
    </div>
  );

  submitForm = (formValues) => {
    this.props.updateTv(this.props.sid, formValues, this.props.cookie);
    // this.props.reset();
  };

  render() {
    const {
      error,
      handleSubmit,
      pristine,
      reset,
      submitting,
      invalid,
      submitSucceded,
    } = this.props;
    return (
      <div className="container mt-3">
        <form onSubmit={handleSubmit(this.submitForm)}>
          <div className="card border-secondary mb-3">
            <div className="card-header">TV Input</div>
            <div className="card-body text-secondary">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <Field
                    name="brand"
                    type="text"
                    label="Brand"
                    component={this.renderInput}
                  ></Field>
                </div>
                <div className="form-group col-md-6">
                  <Field
                    name="size"
                    type="number"
                    label="Size"
                    component={this.renderInput}
                  ></Field>
                </div>
              </div>
              <div className="form-group">
                <Field
                  name="description"
                  type="text"
                  label="Description"
                  component={this.renderInput}
                ></Field>
              </div>
              {/* <div class="form-group">
                                <label for="piclink">Pic Link</label>
                                <input type="text" class="form-control" id="piclink" placeholder="Pic http link" />
                            </div> */}
              <div className="form-row">
                <div className="form-group col-md-6">
                  <Field
                    name="price"
                    type="number"
                    label="Price"
                    component={this.renderInput}
                  ></Field>
                </div>
                <div className="form-group col-md-6">
                  <Field
                    name="rating"
                    type="number"
                    label="Rating"
                    component={this.renderInput}
                  ></Field>
                </div>
              </div>
              <button
                className="btn btn-primary"
                disabled={invalid || submitSucceded}
              >
                Update TV
              </button>
              <button
                className="btn btn-secondary ml-3"
                onClick={reset}
                disabled={pristine || submitSucceded}
              >
                Reset
              </button>
              <Link to="/tvadd">
                <button className="btn btn-warning ml-3">Back</button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sid: ownProps.match.params.id,
  initialValues: state.tvadmin.tvInfo,
  cookie: state.tvadmin.token,
});

const mapDispatchToProps = {
  editTv,
  updateTv,
};

const validate = (values) => {
  const errors = {};
  if (!values.brand) {
    errors.brand = "Required";
  } else if (values.brand.length > 15) {
    errors.brand = "length must less 15";
  }
  if (!values.size) {
    errors.size = "Required";
  }
  if (!values.description) {
    errors.description = "Required";
  }
  if (!values.price) {
    errors.price = "Required";
  }
  if (!values.rating) {
    errors.rating = "Required";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "tvform",
  validate,
})(EditTv);

export default connect(mapStateToProps, mapDispatchToProps)(formWrapped);
