import React, { Component } from "react";
import "./admin.css";
import axios from "axios";
import { withCookies } from "react-cookie";
import history from "../History";

class Admin extends Component {
  state = {
    username: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      username: this.state.username,
      password: this.state.password,
    };
    axios
      .post("http://winbigcareer.com:8000/auth/", user)
      .then((res) => {
        console.log(res.data.token);
        this.props.cookies.set("admin-token", res.data.token);
        history.push("/tvadd");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  render() {
    const { username, password } = this.state;
    return (
      <div className="sign-modify">
        <form className="form-signin" onSubmit={this.handleSubmit}>
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>

          <label htmlFor="inputUsername" className="sr-only">
            User Name
          </label>
          <input
            name="username"
            value={username}
            onChange={(event) => this.handleChange(event)}
            type="username"
            id="inputUsername"
            className="form-control"
            placeholder="User Name"
            required
            autoFocus
          />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            name="password"
            value={password}
            onChange={(event) => this.handleChange(event)}
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="Password"
            required
          />
          <div className="checkbox mb-3"></div>
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Sign in
          </button>
        </form>
      </div>
    );
  }
}

export default withCookies(Admin);
