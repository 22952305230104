import React, { Component } from "react";
import { connect } from "react-redux";
import { infoTv, addPic } from "../../actions/tvadminActions";
import { Link } from "react-router-dom";
import Adminpic from "./adminpic";
import { isSigned } from "./isSigned";
import Alert from "../alert";

export class Tvinfo extends Component {
  // state = {
  //   piclink: null
  // }

  componentDidMount() {
    isSigned(this.props.cookie);
    this.props.infoTv(this.props.match.params.id, this.props.cookie);
  }

  handleChange = (e) => {
    this.setState({
      piclink: e.target.files[0],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.piclink) {
      const uploadData = new FormData();
      uploadData.append("tvs", this.props.match.params.id);
      uploadData.append("piclink", this.state.piclink);

      // console.log(value);

      this.props.addPic(uploadData, this.props.cookie);
      this.setState({
        piclink: null,
      });
    } else {
      alert("Upload image null");
    }
  };

  render() {
    if (this.props.infotv) {
      const {
        id,
        brand,
        size,
        description,
        price,
        rating,
        piclink,
      } = this.props.infotv;
      return (
        <div className="container mt-3">
          <Alert />
          <div>
            <form onSubmit={this.handleSubmit}>
              <div className="input-group is-invalid">
                <div className="custom-file">
                  <input
                    type="file"
                    className="piclink"
                    id="validatedInputGroupCustomFile"
                    required
                    onChange={(e) => this.handleChange(e)}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="validatedInputGroupCustomFile"
                  >
                    Choose Picture...
                  </label>
                </div>
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" type="submit">
                    Add Picture
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-3">
              <Adminpic piclink={piclink} />
            </div>
            <div className=" col-sm-6 d-flex align-items-center mt-3">
              <div className="card col-12">
                <h5 className="card-header">Information</h5>
                <div className="card-body">
                  <h5 className="card-title">
                    {brand} {size}
                  </h5>
                  <p className="card-text">{description}</p>
                  <h5 className="card-title">${price}</h5>
                </div>
                <div>
                  <Link to="/tvadd">
                    <button className="btn btn-warning btn-block">Back</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

const mapStateToProps = (state) => ({
  infotv: state.tvadmin.infoTv,
  cookie: state.tvadmin.token,
});

const mapDispatchToProps = {
  infoTv,
  addPic,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tvinfo);
