export const DECREASE = "DECREASE";
export const INCREASE = "INCREASE";
export const REMOVE = "REMOVE";
export const CLEAR_CART = "CLEAR_CART";
export const GET_TOTAL = "GET_TOTAL";
export const TOGGLE_AMOUNT = "TOGGLE_AMOUNT";
export const FETCH_TVS = "FETCH_TVS";
export const FETCH_TV = "FETCH_TV";
export const UPDATE_TVS = "UPDATE_TVS";
export const FETCH_SLIDER = "FETCH_SLIDER";
export const ADD_CART = "ADD_CART";
export const GET_CART = "GET_CART";
export const ADD_TV = "ADD_TV";
export const EDIT_TV = "EDIT_TV";
export const UPDATE_TV = "UPDATE_TV";
export const ADD_TVPIC = "ADD_TVPIC";
export const DELETE_TV = "DELETE_TV";
export const LIST_TV = "LIST_TV";
export const INFO_TV = "INFO_TV";
export const DELETE_TVPIC = "DELETE_TVPIC";
export const ADD_COOKIE = "ADD_COOKIE";
export const GET_ERRORS = "GET_ERRORS";
export const GET_MESSAGES = "GET_MESSAGES";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
