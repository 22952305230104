import React, { Component } from "react";
import { withAlert } from "react-alert";
import { getErrors } from "../actions/errorActions";
import { connect } from "react-redux";
import messages from "../reducers/messages";

class Alert extends Component {
  componentDidUpdate(prevProps) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      if (error.msg.username) alert.error(error.msg.username.join());
      if (error.msg.email) alert.error(error.msg.email.join());
    }

    if (message !== prevProps.message) {
      if (message.userAdded) alert.success(message.userAdded);
      if (message.addTvok) alert.success(message.addTvok);
      if (message.deleteTvok) alert.success(message.deleteTvok);
      if (message.updateTvok) alert.success(message.updateTvok);
      if (message.addPicok) alert.success(message.addPicok);
      if (message.deletePicok) alert.success(message.deletePicok);
    }
  }

  render() {
    return <div></div>;
  }
}
const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

const mapDispatchToProps = {
  getErrors,
};

const Alertcombin = withAlert()(Alert);

export default connect(mapStateToProps, mapDispatchToProps)(Alertcombin);
