import { combineReducers } from "redux";
import tvReducer from "./tvReducer";
import cartReducer from "./cartReducer";
import { reducer as fromReducer } from "redux-form";
import tvadminReducer from "./tvadminReducer";
import errors from "./errors";
import messages from "./messages";

export default combineReducers({
  tvs: tvReducer,
  cart: cartReducer,
  form: fromReducer,
  tvadmin: tvadminReducer,
  errors,
  messages,
});
