import React, { Component } from "react";
import { connect } from "react-redux";
import { listTv } from "../../actions/tvadminActions";
import Listitem from "./listitem";
import headers from "./headers";

export class Tvlist extends Component {
  componentDidMount() {
    this.props.listTv(this.props.admin_token);
  }

  render() {
    return (
      <div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th width="30%">Brand</th>
                <th width="20%">Size</th>
                <th width="20%">Price</th>
                <th width="10%"></th>
                <th width="10%"></th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {this.props.tvlist.map((item, index) => (
                <Listitem key={index} item={item} />
              ))}
            </tbody>
          </table>
        </div>
        <div></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tvlist: state.tvadmin.tvList,
  cookie: state.tvadmin.token,
});

const mapDispatchToProps = {
  listTv,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tvlist);
