import React from "react";
import { Link } from "react-router-dom";

export const Showitem = ({ item }) => {
    const { piclink, brand, size, price, rating, description, id } = item;
    return (
        <div className="card m-3" style={{ width: "20rem" }}>
            <img src={piclink[0]} className="card-img-top" alt="tvpic" />
            <div className="card-body">
                <h5 className="card-title">
                    {brand} {size} <strong className="float-right">${price}</strong>
                </h5>
                <p className="card-text">{description}</p>
                <p className="card-text">
                    <font color="#ddd">
                        {Array.from(Array(rating), (e, i) => (
                            <span key={i}>★</span>
                        ))}
                    </font>
                    <Link to={`/showinfo/${id}`}>
                    <button className="btn btn-sm btn-success float-right">
                        Info
                        </button></Link>
                </p>
               
            </div>
        </div>
    );
};
