import React from "react";
import { withCookies } from "react-cookie";

function TvadminHeader({ cookies }) {
  const onlogout = () => {
    cookies.remove("admin-token");
    window.location.href = "/admin";
  };
  return (
    <div className="row mb-3">
      <div className="col-6">Mark2Win</div>
      <div className="col-6">
        <span className="float-right" onClick={onlogout}>
          Logout
        </span>
      </div>
    </div>
  );
}

export default withCookies(TvadminHeader);
