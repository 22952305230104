import React, { Component } from "react";
import { connect } from 'react-redux';
import { getCart } from "../../actions/cartActions";
import { Link } from 'react-router-dom';
import Cartlist from './cartlist';
import CartHeater from './cartHeater'

class Cart extends Component {



    render() {

        const display = this.props.cart.cart.length === 0 ? <div className="text-center">Empty Cart</div> : <div><CartHeater /><Cartlist /></div>;

        return (
            <div className="container mt-3">
                <div id="showshoppingcart">
                    <div className="card-header">Shopping Cart</div>
                    <div className="row justify-content-center mt-5">
                        <div className="container m-3" id="showcartdetail">
                            
                            {display}
                        </div>
                    </div>
                    <div className="col">
                        <Link to="/"><button className="btn btn-primary btn-block">Back to Home</button></Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

export default connect(mapStateToProps, { getCart })(Cart)