import React from "react";
import Sidebar from "react-sidebar";
import AdminNav from './adminNav'
import AdminHeader from './adminHeader'

const mql = window.matchMedia(`(min-width: 800px)`);

class Adminmain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          sidebarOpen: true
        };
        
      }
    
      onSetSidebarOpen = (open)=> {
        // alert("ok")
        this.setState({ sidebarOpen: open });
      }
    
      render() {
        return (
          <div>
 {/* <AdminHeader onSetSidebarOpen={this.onSetSidebarOpen}/> */}
          <Sidebar
            sidebar={<AdminNav />}
            open={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
            styles={{ sidebar: { background: "#212529" , color: "#ffffff"} }}
          >

            <button onClick={() => this.onSetSidebarOpen(true)}>
            <i class="fas fa-bars" ></i>
            </button>
          </Sidebar>
          </div>
         
        );
      }
    }
export default Adminmain;
