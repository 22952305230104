import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addTv, addCookie } from "../../actions/tvadminActions";
import Tvlist from "./tvlist";
import TvadminHeader from "./tvadminHeader";
import { withCookies } from "react-cookie";
import Alert from "../alert";

export class Tvadd extends Component {
  componentDidMount() {
    if (!this.props.cookies.get("admin-token")) {
      window.location.href = "/admin";
    } else {
      if (!this.props.cookie) {
        // console.log('add Cookie', this.props.cookies.get('admin-token'));

        this.props.addCookie(this.props.cookies.get("admin-token"));
      }
    }
  }

  renderInput = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          className="form-control"
          type={type}
          {...input}
          placeholder={label}
        />
        {touched && error && <span className="text-danger">{error}</span>}
      </div>
    </div>
  );

  submitForm = (formValues) => {
    this.props.addTv(formValues, this.props.cookies.get("admin-token"));
    this.props.reset();
  };

  render() {
    const {
      error,
      handleSubmit,
      pristine,
      reset,
      submitting,
      invalid,
      submitSucceded,
    } = this.props;
    return (
      <div className="container mt-3">
        <TvadminHeader />
        <Alert />
        <form onSubmit={handleSubmit(this.submitForm)}>
          <div className="card border-secondary mb-3">
            <div className="card-header">TV Input</div>
            <div className="card-body text-secondary">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <Field
                    name="brand"
                    type="text"
                    label="Brand"
                    component={this.renderInput}
                  ></Field>
                </div>
                <div className="form-group col-md-6">
                  <Field
                    name="size"
                    type="number"
                    label="Size"
                    component={this.renderInput}
                  ></Field>
                </div>
              </div>
              <div className="form-group">
                <Field
                  name="description"
                  type="text"
                  label="Description"
                  component={this.renderInput}
                ></Field>
              </div>
              {/* <div class="form-group">
                                <label for="piclink">Pic Link</label>
                                <input type="text" class="form-control" id="piclink" placeholder="Pic http link" />
                            </div> */}
              <div className="form-row">
                <div className="form-group col-md-6">
                  <Field
                    name="price"
                    type="number"
                    label="Price"
                    component={this.renderInput}
                  ></Field>
                </div>
                <div className="form-group col-md-6">
                  <Field
                    name="rating"
                    type="number"
                    label="Rating"
                    component={this.renderInput}
                  ></Field>
                </div>
              </div>
              <button
                className="btn btn-primary"
                disabled={invalid || submitSucceded}
              >
                Add TV
              </button>
              <button
                className="btn btn-secondary ml-3"
                onClick={reset}
                disabled={pristine || submitSucceded}
              >
                Reset
              </button>
            </div>
          </div>
        </form>
        <Tvlist admin_token={this.props.cookies.get("admin-token")} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cookie: state.tvadmin.token,
});

const mapDispatchToProps = {
  addTv,
  addCookie,
};

const validate = (values) => {
  const errors = {};
  if (!values.brand) {
    errors.brand = "Required";
  } else if (values.brand.length > 15) {
    errors.brand = "length must less 15";
  }
  if (!values.size) {
    errors.size = "Required";
  }
  if (!values.description) {
    errors.description = "Required";
  }
  if (!values.price) {
    errors.price = "Required";
  }
  if (!values.rating) {
    errors.rating = "Required";
  }
  return errors;
};

const TvaddCookie = withCookies(Tvadd);

const formWrapped = reduxForm({
  form: "tvform",
  validate,
})(TvaddCookie);

export default connect(mapStateToProps, mapDispatchToProps)(formWrapped);
