import { DECREASE, INCREASE, CLEAR_CART, REMOVE, GET_TOTAL, ADD_CART, GET_CART } from "../actions";

export const addCart = (item, qty) => {
    return {
        type: ADD_CART,
        payload: {
            id: item.id,
            brand: item.brand,
            description: item.description,
            size: item.size,
            piclink: item.piclink[0],
            price: item.price,
            amount: qty
        }
    }
}

export const getCart = () => {
    return { type: GET_CART }
}

export const remove = id => dispatch => {
    dispatch(
        {
            type: REMOVE,
            payload: { id }
        }
    )
}

export const increase = (id, amount) => dispatch => {
    dispatch({
        type: INCREASE,
        payload: { id, amount }
    })
}

export const decrease = (id, amount) => dispatch => {
    dispatch({
        type: DECREASE,
        payload: { id, amount }
    })

}