import {
    DECREASE,
    INCREASE,
    CLEAR_CART,
    REMOVE,
    GET_TOTAL,
    ADD_CART,
    GET_CART,
} from "../actions";

const initialState = {
    cart: [],
    total: 0,
    total_amount: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_CART:
            console.log("this si ADD_CART");
            console.log(action.payload);
            console.log(state.cart);
            let typeCart = 0
            state.cart.find((id) => id.id === action.payload.id ? typeCart = 1 : typeCart = 0)
            if (typeCart === 1) {
                let tempCart = state.cart.map((item) =>
                    item.id === action.payload.id ?
                        { ...item, amount: item.amount + action.payload.amount } : { ...item }
                )
                return { ...state, cart: tempCart }
            } else {
                return { ...state, cart: [...state.cart, action.payload] };
            }

        case GET_CART:
            return state;

        case REMOVE:
            return {
                ...state,
                cart: state.cart.filter((item) => item.id !== action.payload.id)
            };

        case DECREASE:
            let tempCart = [];
            if (action.payload.amount === 1) {
                tempCart = state.cart.filter((item) => item.id !== action.payload.id);
            } else {
                tempCart = state.cart.map((item) =>
                    item.id === action.payload.id
                        ? { ...item, amount: item.amount - 1 }
                        : { ...item }
                );
            }
            return { ...state, cart: tempCart };

        case INCREASE:
            let tempCart1 = state.cart.map((item) =>
                item.id === action.payload.id
                    ? { ...item, amount: item.amount + 1 }
                    : { ...item }
            );
            return { ...state, cart: tempCart1 };




        default:
            return state;
    }
}
